import * as React from 'react'
import { withNamespaces } from 'react-i18next'
import { 
    Row, 
    Col, 
    Button,
    Label
} from 'reactstrap'

const checkBasedOnConditions = (question, questions, answers) => {
    if(!question.based_on?.question) return true

    let relatedQuestion = null

    for(let i = 0; i < questions.length; i++){
        const sectionQuestions = questions[i].questions || []

        relatedQuestion = sectionQuestions.find((questionToSearch) => {
            return questionToSearch.front_id === question.based_on.question
        })

        if(relatedQuestion){
            break
        }
    }

    if(relatedQuestion){
        const relatedQuestionAnswer = answers.find((ans) => {
            return ans.question === relatedQuestion.id
        })?.answer

        if(Array.isArray(relatedQuestionAnswer)){
            const selectedOption = (relatedQuestion.options || []).find((option) => {
                return option.id === relatedQuestionAnswer[0].value
            })

            return selectedOption?.front_id === question.based_on.option
        }
    }

    return false
}

const SubQuestion = withNamespaces()(({
    t,
    question,
    answer
}) => {
    const {
        title,
        type
    } = question

    switch(type){
        case 'textarea':
        case 'text':
        case 'country_list':
        case 'select':
        case 'file_upload':
        case 'date':
            return (
                <div className="question">
                    <Label className="form-label question-title">
                        {t(title)}
                    </Label>
                    <p>
                        {answer?.title}
                    </p>
                </div>
            )
        default:
            break
    }
})

const Question = withNamespaces()(({
    t,
    question, 
    answer: {
        answer = []
    } = {}
}) => {
    const {
        title,
        type
    } = question

    switch(type){
        case 'textarea':
        case 'text':
        case 'country_list':
        case 'select':
            return (
                <div className="question">
                    <Label className="form-label question-title">
                        {t(title)}
                    </Label>
                    <p>
                        {answer[0]?.title}
                    </p>
                </div>
            )
        
        case 'date':
            return (
                <div className="question">
                    <Label className="form-label question-title">
                        {t(title)}
                    </Label>
                    <p>
                        {answer[0]?.title}
                    </p>
                </div>
            )
        
        case 'checkbox':
        case 'category':
            return (
                <div className="question">
                    <Label className="form-label question-title">
                        {t(title)}
                    </Label>
                    <Row>
                        <Col sm="12" md="6">
                            <p>
                                {t('Checked 1')}
                            </p>
                        </Col>
                        <Col sm="12" md="6">
                            <p>
                                {t('Checked 2')}
                            </p>
                        </Col>
                    </Row>
                </div>
            )

        case 'radiobox':{
            const selectedOption = question.options.find((op) => op.id === answer[0]?.value)

            const children = selectedOption?.children || []

            return (
                <div className="question">
                    <Label className="form-label question-title">
                        {t(title)}
                    </Label>
                    <Row>
                        <Col sm="12">
                            <Label className="form-label question-title">
                                {t(answer[0]?.title)}
                            </Label>
                            {children.length > 0 && (
                                <div className="sub-question-container">
                                    {children.map((childQuestion, index) => {
                                        const childAns = (answer[0]?.children || []).find((ans) => {
                                            return ans.id === childQuestion.id
                                        })

                                        return (
                                            <SubQuestion 
                                                key={index}
                                                question={childQuestion}
                                                answer={childAns}
                                            />
                                        )
                                    })}
                                </div>
                            )}
                        </Col>
                    </Row>
                </div>
            )
        }
        
        case 'file_upload':
            const uploadedFiles = answer || []
            
            return (
                <div className="question">
                    <Label className="form-label question-title">
                        {t(title)}
                    </Label>

                    {uploadedFiles.length > 0 && (
                        <div className="files-container">
                            {uploadedFiles.map((file, index) => {
                                return (
                                    <div className="file-preview" key={index}>
                                        <span className="file-name">
                                            { file.name }
                                        </span>
                                        <img alt="" />
                                    </div>
                                )
                            })}
                        </div>
                    )}
                </div>
            )
        default:
            break
    }

    return question.title
})

export const AnswerSheet = withNamespaces()(({
    t,
    settings,
    questions,
    answers,
    onBackButtonClicked,
    onConfirmed
}) => {
    const findAnswer = ({
        id
    }) => {
        return (answers || []).find((answer) => {
            return answer.question === id
        })
    }


    const renderQuestions = (panels) => {
        return (panels || []).map((panel) => {
            if(!panel.title){
                return (panel.questions || []).map((question, index) => {
                    const displayQuestion = checkBasedOnConditions(
                        question, questions, answers
                    )

                    if(!displayQuestion) return null

                    return (
                        <Question
                            key={index}
                            question={question}
                            answer={findAnswer(question)}
                        />
                    )
                })
            }

            return (
                <div className='sub-section'>
                    <div className='sub-section-title'>
                        <h4>{ t(panel.title) }</h4>
                    </div>
                    <div className='sub-section-items'>
                        {(panel.questions || []).map((question, index) => {
                            const displayQuestion = checkBasedOnConditions(
                                question, questions, answers
                            )

                            if(!displayQuestion) return null

                            return (
                                <Question
                                    key={index}
                                    question={question}
                                    answer={findAnswer(question)}
                                />
                            )
                        })}
                    </div>
                </div>
            )

        })
    }

    return (
        <div className='smartintegrity__questionnaire_answer_sheet'>
            <Row>
                <Col sm='12'>
                    <div className='sections-container'>
                        {questions.map((section, index) => {
                            return (
                                <div className='section' key={index}>
                                    <div className='section-header'>
                                        <img 
                                            src={`/images/questionnaire/pages/${section.icon}.svg`} 
                                            alt='' 
                                        />
                                        <span>{`${index + 1}. ${section.title}`}</span>
                                    </div>

                                    <div className='section-body'>
                                        { renderQuestions(section.panels) }
                                    </div>
                                </div>
                            )
                        })}
                    </div>
                </Col>
            </Row>

            <Row className='mb-4'>
                <Col sm='12' className='d-flex justify-content-between align-items-center'>
                    {
                        settings?.buttons?.back?.enabled && (
                            <Button
                                onClick={() => {
                                    if(onBackButtonClicked){
                                        onBackButtonClicked()
                                    }
                                }}
                                color='secondary'
                                type='button'>
                                {settings.buttons.back?.title || t('Back')}
                            </Button>
                        )
                    }

                    {
                        settings?.buttons?.confirm?.enabled && (
                            <Button
                                onClick={() => {
                                    if(onConfirmed){
                                        onConfirmed()
                                    }
                                }}
                                color='btn btn-primary tpdd-btn'
                                type='button'>
                                {settings.buttons.confirm?.title || t('Confirm')}
                            </Button>
                        )
                    }
                </Col>
            </Row>
        </div>
    )
})