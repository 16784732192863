import * as React from 'react'
import axios from 'axios'

const useAutoSave = ({
    enable,
    onlyChangedItems,
    stepInterval,
    saveRequestOptions
}) => {
    const [autoSaveOptions, setAutoSaveOptions] = React.useState({
        enable: !!enable,
        saveOnlyChangedItems: !!onlyChangedItems,
        saveEvery: stepInterval || 10,
        unsavedSteps: 0,
        oldUnsavedSteps: 0,
        questionnaireCompleted: false,
        latestRequestState: 'ready_to_send'
    })

    const [ requestOptions, setRequestOptions ] = React.useState(saveRequestOptions || {})

    const [payload, setPayload] = React.useState({})

    const saveQuestionnaireCurrentState = () => {
        if(requestOptions && requestOptions.requestUrl){
            const requestUrl =
                typeof requestOptions.requestUrl === 'function'
                ? requestOptions.requestUrl()
                : requestOptions.requestUrl

            const headers = {
                headers: {
                    ...(requestOptions?.headers || {})
                }
            }

            const payloadToSend = 
                typeof requestOptions.payload === 'function'
                ? requestOptions.payload(payload)
                : payload

            setAutoSaveOptions((currentState) => {
                return {
                    ...currentState,
                    latestRequestState: 'in_process',
                    oldUnsavedSteps: currentState.unsavedSteps,
                    unsavedSteps: 0
                }
            })

            axios.post(requestUrl, payloadToSend, headers)
            .then(() => {
                setAutoSaveOptions((currentState) => {
                    return {
                        ...currentState,
                        latestRequestState: 'succeed'
                    }
                }) 
            })
            .catch(() => {
                setAutoSaveOptions((currentState) => {
                    return {
                        ...currentState,
                        unsavedSteps: currentState.oldUnsavedSteps + currentState.unsavedSteps,
                        oldUnsavedSteps: 0,
                        latestRequestState: 'failed'
                    }
                })
            })
        }
    }

    React.useEffect(() => {
        if(autoSaveOptions.unsavedSteps && 
            autoSaveOptions.unsavedSteps >= autoSaveOptions.saveEvery && 
            autoSaveOptions.latestRequestState !== 'in_process'){
            saveQuestionnaireCurrentState()
        }
    }, [autoSaveOptions.unsavedSteps])

    React.useEffect(() => {
        if(autoSaveOptions.questionnaireCompleted){
            saveQuestionnaireCurrentState()
        }
    }, [autoSaveOptions.questionnaireCompleted])

    return {
        control: {
            autoSaveState: {
                enabled: autoSaveOptions.enable,
                unsavedStepsCount: autoSaveOptions.unsavedSteps,
                latestRequestState: autoSaveOptions.latestRequestState,
                questionnaireCompleted: autoSaveOptions.questionnaireCompleted,
                payload: payload
            },
            autoSaveUpdatePayload: (gotPayload) => {
                setPayload(gotPayload)
                setAutoSaveOptions((currentState) => {
                    return {
                        ...currentState,
                        unsavedSteps: currentState.unsavedSteps + 1
                    }
                })
            },
            autoSaveQuestionnaireCompleted: (latestPayload) => {
                setPayload(latestPayload)
                setAutoSaveOptions((currentState) => {
                    return {
                        ...currentState,
                        questionnaireCompleted: true
                    }
                })
            },
            autoSaveUpdateOptions: (callback) => {
                if(typeof callback === 'function'){
                    const {
                        enable: enableNewStatus,
                        saveRequestOptions: newSaveRequestOptions
                    } = callback({
                        enable: autoSaveOptions.enable,
                        saveRequestOptions: {
                            ...requestOptions
                        }
                    })

                    setAutoSaveOptions((currentState) => {
                        return {
                            ...currentState,
                            enable: enableNewStatus
                        }
                    })

                    setRequestOptions(newSaveRequestOptions)
                }
            },
            autoSaveSaveCurrentState: () => {
                saveQuestionnaireCurrentState()
            }
        }
    }
}

export {
    useAutoSave
}
