import * as React from 'react'
import { withNamespaces } from 'react-i18next'
import {
    FormGroup,
    Label,
    FormFeedback,
    Card,
    Row,
    Col,
    Progress
} from 'reactstrap'
import { useDropzone } from 'react-dropzone'
import axios from 'axios'
import { Controller } from 'react-hook-form'
import {
    UnknownFileTypePlaceholder
} from '../../../../../constants'

const Dropzone = withNamespaces()(({ multiple, onChange, t, ...rest }) => {
    const { getRootProps, getInputProps } = useDropzone({
        multiple,
        ...rest
    })

    return (
        <div className='dropzone p-3'>
            <div className='dz-message needsclick' {...getRootProps()}>
                <input
                    {...getInputProps({
                        onChange
                    })}
                />
                <button type='button' className='btn select-files-btn'>
                    {t('Select files...')}
                </button>
                <h4>{t('Drop files here or click to upload')}</h4>
            </div>
        </div>
    )
})

const FileUploader = withNamespaces()(({
    name,
    label,
    validation,
    settings,
    displayQuestion,
    questionTitlePrefix,
    t,
    ...rest
}) => {
    const {
        control,
        formState: { errors, defaultValues }
    } = rest
    const [uploadedFiles, setUploadedFiles] = React.useState([])

    const dropzoneOptions = {
        accepted:
            'text/plain, application/pdf, application/msword, application/vnd.ms-excel, application/vnd, application/vnd.openxmlformats-officedocument.wordprocessingml.document, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.openxmlformats-officedocument.presentationml.presentation, image/*, audio/*, video/mp4, video/x-mpegURL, video/MP2T, video/3gpp, video/quicktime, video/x-msvideo, video/x-ms-wmv',
        description: [
            `${t('You are allowed to upload limited file types')}. ${t(
                'For more information'
            )}, ${t('visit')}`,
            <a
                key='1'
                rel='noreferrer'
                href='https://diss-co.tech/faq-diss-co/'
                target='_blank'
            >{` ${t('FAQ')} `}</a>,
            t('page')
        ]
    }

    const onUploadProgress = (e, file, setUploadedFiles) => {
        const { total, loaded, lengthComputable } = e
        if (lengthComputable) {
            const uploadedPercent = (loaded / total) * 100

            setUploadedFiles((uploadedFiles) => {
                const index = uploadedFiles.findIndex(
                    (f) => f.preview === file.preview
                )

                if (index > -1) {
                    uploadedFiles[index].uploadedPercent = uploadedPercent
                }

                return [...uploadedFiles]
            })
        }
    }

    const handleClickDeleteFile = (file) => {
        const { deleteRequestOptions } = settings || {}

        const headers = {
            headers: {
                ...(deleteRequestOptions?.headers || {})
            }
        }

        const deletedFile = {
            file: file.name,
            report_case_id: file.id,
            ...(deleteRequestOptions?.payload || {})
        }

        setUploadedFiles((uploadedFiles) => {
            const index = uploadedFiles.findIndex((f) => f.id === file.id)

            if (index > -1) {
                uploadedFiles[index].status = 'deleting'

                return [...uploadedFiles]
            }

            return [...uploadedFiles]
        })

        const requestUrl =
            typeof deleteRequestOptions.requestUrl === 'function'
                ? deleteRequestOptions.requestUrl({
                      questionId: name,
                      file: file
                  })
                : deleteRequestOptions.requestUrl

        axios.post(requestUrl, deletedFile, headers).then(() => {
            setUploadedFiles(() => {
                const result = [
                    ...uploadedFiles.filter((f) => {
                        return f.id !== file.id
                    })
                ]

                return result
            })
        })
    }

    const handleAcceptedFiles = (files, field) => {
        const { uploadRequestOptions } = settings || {}
        const headers = {
            headers: {
                ...(uploadRequestOptions?.headers || {})
            },
            onUploadProgress: null
        }

        files.map((xFile) => {
            const formData = new window.FormData()

            formData.append('file', xFile)

            const file = {
                ...xFile,
                id: null,
                status: 'uploading',
                name: xFile.name,
                type: xFile.type,
                questionId: name,
                uploadedPercent: 0,
                preview: URL.createObjectURL(xFile),
                formattedSize: xFile.size,
                ...(uploadRequestOptions?.payload || {})
            }

            setUploadedFiles((uploadedFiles) => {
                return [...uploadedFiles, { ...file }]
            })

            const requestUrl =
                typeof uploadRequestOptions.requestUrl === 'function'
                    ? uploadRequestOptions.requestUrl({
                          questionId: name
                      })
                    : uploadRequestOptions.requestUrl

            axios
                .post(requestUrl, formData, {
                    ...headers,
                    onUploadProgress: (e) => {
                        onUploadProgress(e, file, setUploadedFiles)
                    }
                })
                .then((response) => {
                    if (response.status === 200) {
                        const fileData = response.data.data

                        setUploadedFiles((currentState) => {
                            const resultToSend = [
                                ...currentState
                            ]

                            const index = resultToSend.findIndex(
                                (f) => f.preview === file.preview
                            )

                            if(index > -1){
                                resultToSend[index].status = 'upload_succeed'
                                resultToSend[index].id = fileData.id
                            }

                            field.onChange(resultToSend)

                            return resultToSend
                        })
                    }
                    else{
                        setUploadedFiles((currentState) => {
                            const index = currentState.findIndex(
                                (f) => f.preview === file.preview
                            )
                            const newState = [...currentState]

                            if(index > -1){
                                newState[index] = 'upload_failed'
                            }
                        
                            return newState
                        })
                    }
                })
                .catch((_error) => {
                    setUploadedFiles((uploadedFiles) => {
                        const index = uploadedFiles.findIndex(
                            (f) => f.preview === file.preview
                        )

                        if (index > -1) {
                            uploadedFiles[index].status = 'upload_failed'

                            return [...uploadedFiles]
                        }

                        return [...uploadedFiles]
                    })
                })
        })
    }

    React.useEffect(() => {
        if (defaultValues && defaultValues[name]) {
            const prevUploadedFiles = defaultValues[name]
            setUploadedFiles([
                ...prevUploadedFiles.map((preUploadedFile) => {
                    return {
                        questionId: name,
                        id: preUploadedFile.id,
                        title: preUploadedFile.title,
                        name: preUploadedFile.name
                    }
                })
            ])
        }

        control.register(name, {
            required: !!validation?.required
        })
        
        return () => {
            control.unregister(name)
        }
    }, [])

    React.useEffect(() => {
        if(!displayQuestion){
            control.unregister(name)
        }
        else{
            control.register(name, {
                required: !!validation?.required
            })
        }
    }, [ displayQuestion ])

    return (
        <FormGroup>
            {label && <Label for={name}>
                {questionTitlePrefix}{t(label)}
                </Label>}
            <Controller
                name={name}
                control={control}
                render={({ field }) => {
                    return (
                        <Dropzone
                            multiple
                            onDrop={(files) => {
                                handleAcceptedFiles(files, field)
                            }}
                            accept={dropzoneOptions.accepted}
                        />
                    )
                }}
            />
            <div className='dropzone-previews'>
                {uploadedFiles.map((f, i) => {
                    return (
                        <Card
                            className='mb-0 shadow-none dz-processing dz-image-preview dz-success dz-complete'
                            key={i + '-file'}
                        >
                            <div className='p-3'>
                                <Row className='align-items-center'>
                                    <Col className='col-auto'>
                                        <img
                                            data-dz-thumbnail=''
                                            height='80'
                                            className='avatar-sm rounded'
                                            alt={f.name}
                                            src={f.preview || UnknownFileTypePlaceholder}
                                        />
                                    </Col>
                                    <Col>
                                        <a href='#' className='file-name'>
                                            {f.name} {f.uploaded}
                                        </a>
                                        <p className='upload-details-container'>
                                            <span className='file-size'>
                                                {(
                                                    (f.formattedSize || 0) / 1024
                                                ).toFixed(2)}{' '}
                                                KB
                                            </span>
                                            {f.status === 'upload_succeed' && (
                                                <span className='upload-status-message succeed'>
                                                    {t(
                                                        'File(s) successfully uploaded.'
                                                    )}
                                                </span>
                                            )}
                                            {f.status === 'upload_failed' && (
                                                <span className='upload-status-message failed'>
                                                    {t(
                                                        'File(s) failed to upload.'
                                                    )}
                                                </span>
                                            )}
                                        </p>
                                    </Col>
                                    <Col xs={2} className='actions-container'>
                                        {f.status === 'uploading' && (
                                            <span className='uploaded-percent'>
                                                {Math.ceil(f.uploadedPercent)}%
                                            </span>
                                        )}

                                        <button
                                            type='button'
                                            className='btn'
                                            onClick={() =>
                                                f.status !== 'uploading' &&
                                                f.status !== 'deleting' &&
                                                handleClickDeleteFile(f)
                                            }
                                        >
                                            {f.status === 'uploading' ||
                                            f.status === 'deleting' ? (
                                                <i className='ri-forbid-line font-size-20' />
                                            ) : (
                                                <i className='ri-close-line font-size-20' />
                                            )}
                                        </button>
                                    </Col>
                                </Row>
                            </div>
                            {f.status === 'uploading' && (
                                <Progress
                                    value={Math.ceil(f.uploadedPercent)}
                                />
                            )}
                        </Card>
                    )
                })}
            </div>
            {errors && errors[name] && (
                <FormFeedback className='d-block' valid={false}>
                    {t(`This field cannot be blank`)}
                </FormFeedback>
            )}
        </FormGroup>
    )
})

export { FileUploader }
