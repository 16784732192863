import * as React from 'react'
import { FormProvider, useForm, useWatch } from 'react-hook-form'
import { withNamespaces } from 'react-i18next'
import { Row, Col, Button, Progress } from 'reactstrap'
import { ReactStrapQuestion } from '../components/Question'
import Accordion from 'react-bootstrap/Accordion'
import {
    makeQuestionsAnswersReadyToSend,
    renderQuestionAnswerByType,
    guessQuestionAnsweredByAnswerType,
    isBasedOnQuestionAnswered
} from '../../helpers/questionnaire'
import {
    QuestionnairePagesImages
} from '../constants'

export const MultiTabQuestionnaire = withNamespaces()(({
    questions,
    defaultValues = {},
    onSubmit,
    onQuestionnaireCompleted,
    settings,
    className,
    onChange,
    t,
    ...elseProps
}) => {
    const methods = useForm({
        defaultValues: defaultValues
    })
    const latestValues = useWatch({
        control: methods.control,
        defaultValues: defaultValues
    })
    const [activeTabIndex, setActiveTabIndex] = React.useState(0)
    const [questionnaireCompleted, setQuestionnaireCompleted] = React.useState(false)
    const [tabsAnsweredQuestions, setTabsAnsweredQuestions] =
        React.useState({})

    const handleSubmit = (data) => {
        onSubmit &&
            onSubmit(
                makeQuestionsAnswersReadyToSend({
                    multiTab: true,
                    questions: questions,
                    values: data
                })
            )
    }

    const reCalculateTabPercent = (tabId) => {
        const checkQuestionIsForTab = (tabId, questionId) => {
            const parentQuestionId = parseInt(
                questionId.toString().split('-')[0]
            )

            return !!questions.find((tab) => {
                return tab.id === tabId
            }).questions.find((q) => {
                return parseInt(q.id) === parentQuestionId
            })
        }

        let completed = true
        let tabTotalRequiredQuestions = 0
        let tabAnsweredRequiredQuestions = 0

        const {
            control: { _fields }
        } = methods

        for (const id in _fields) {
            const field = _fields[id]._f
            if (field.mount) { // field?.required
                const questionAnswered = guessQuestionAnsweredByAnswerType(
                    latestValues[id]
                )

                if (checkQuestionIsForTab(tabId, id)) {
                    tabTotalRequiredQuestions++

                    if (questionAnswered) {
                        tabAnsweredRequiredQuestions++
                    }
                }

                if (!questionAnswered) {
                    completed = false
                }
            }
        }

        setTabsAnsweredQuestions((currentState) => {
            const newState = [...currentState]
            newState[tabId] = {
                required: tabTotalRequiredQuestions,
                answered: tabAnsweredRequiredQuestions
            }
            return newState
        })

        setQuestionnaireCompleted(completed)
    }

    const calculateQuestionnaireStatus = () => {
        questions.forEach((_tabQuestions, tabIndex) => {
            if(questions[tabIndex]){
                reCalculateTabPercent(questions[tabIndex].id)
            }
        })
    }

    const renderQuestions = (panels) => {
        return (panels || []).filter((panel) => {
            const firstDisplableQuestionIndex = panel.questions.findIndex((question) => {
                return (question.based_on && question.based_on.question && !isBasedOnQuestionAnswered(question.based_on, questions, latestValues)) ? false : true
            })

            return firstDisplableQuestionIndex > -1
        }).map((panel, panelIndex) => {
            if(!panel.title){
                return (panel.questions || []).map((question, index) => {
                    const displayQuestion = (question.based_on && question.based_on.question && !isBasedOnQuestionAnswered(question.based_on, questions, latestValues)) ? false : true

                    return (
                        <ReactStrapQuestion
                            key={index}
                            question={question}
                            settings={settings}
                            questionTitlePrefix={`${index + 1}. `}
                            displayQuestion={displayQuestion}
                        />
                    )
                })
            }

            const panelTitlePrefix = tabSettings?.showNumber ? (
                `${String.fromCharCode(97 + panelIndex)}. `
            ) : ''
            
            return (
                <div className='panel'>
                    <div className='panel-title'>
                        <h4>{`${panelTitlePrefix}${t(panel.title)}`}</h4>
                    </div>
                    <div className='panel-items'>
                        {(panel.questions || []).map((question, index) => {
                            const displayQuestion = (question.based_on && question.based_on.question && !isBasedOnQuestionAnswered(question.based_on, questions, latestValues)) ? false : true

                            const questionTitlePrefix = panelTitlePrefix ? (
                                `${panelTitlePrefix.trim()}${index + 1}. `
                            ) : ''

                            return (
                                <ReactStrapQuestion
                                    key={index}
                                    question={question}
                                    settings={settings}
                                    displayQuestion={displayQuestion}
                                    questionTitlePrefix={questionTitlePrefix}
                                />
                            )
                        })}
                    </div>
                </div>
            )

        })
    }

    React.useEffect(() => {
        if(questionnaireCompleted){
            const questionnaireState = makeQuestionsAnswersReadyToSend({
                multiTab: true,
                questions: questions,
                values: latestValues
            })

            if(elseProps.autoSaveState.enabled){
                elseProps.autoSaveQuestionnaireCompleted(
                    questionnaireState
                )
            }

            onQuestionnaireCompleted && onQuestionnaireCompleted(
                questionnaireState
            )
        }
    }, [ questionnaireCompleted ])

    React.useEffect(() => {
        calculateQuestionnaireStatus()

        if(elseProps.autoSaveState.enabled){
            elseProps.autoSaveUpdatePayload(makeQuestionsAnswersReadyToSend({
                multiTab: true,
                questions: questions,
                values: latestValues
            }))
        }
    }, [ latestValues])

    const { tabSettings, buttonsSettings } = settings || {}

    return (
        <FormProvider {...methods}>
            <div className='smartintegrity__questionnaire'>
                <form
                    className={className}
                    onSubmit={methods.handleSubmit(handleSubmit)}
                >
                    <Accordion defaultActiveKey={activeTabIndex}>
                        {questions.filter((tab) => {
                            const firstDisplableQuestionIndex = tab.questions.findIndex((question) => {
                                return (question.based_on && question.based_on.question && !isBasedOnQuestionAnswered(question.based_on, questions, latestValues)) ? false : true
                            })

                            return firstDisplableQuestionIndex > -1
                        }).map((tab, tabIndex) => {
                            const tabAnsweredQuestions = tabsAnsweredQuestions[tab.id]?.answered || 0
                            const tabRequiredQuestions =  tabsAnsweredQuestions[tab.id]?.required || 0
                            let tabPercent = 0

                            if(tabRequiredQuestions){
                                tabPercent = (tabAnsweredQuestions / tabRequiredQuestions * 100).toFixed(0)
                            }

                            return (
                                <Accordion.Item
                                    key={tab.id}
                                    onClick={() => setActiveTabIndex(0)}
                                    className={
                                        activeTabIndex === tabIndex ? 'active' : ''
                                    }
                                    eventKey={tabIndex}
                                >
                                    <Accordion.Header>
                                        <div className='section-header'>
                                            <div>
                                                {QuestionnairePagesImages[tab.icon]?.src && (
                                                    <img src={QuestionnairePagesImages[tab.icon]?.src} alt="" />
                                                )}

                                                <span>
                                                    {`${
                                                        tabSettings?.showNumber &&
                                                        tabIndex + 1 + '.'
                                                    } ${t(tab.title)}`}
                                                </span>
                                            </div>
                                            {tabSettings?.showProgressBar && (
                                                <div className="completed-percent">
                                                    <Progress 
                                                        className={tabPercent >= 100 ? 'completed' : 'incompleted'} 
                                                        color="success" 
                                                        value={tabPercent} 
                                                    />
                                                    <span className='percent-title'>
                                                        {tabPercent >= 100 ? t('Completed') : `${tabPercent}%`}
                                                    </span>
                                                </div>
                                            )}
                                        </div>
                                    </Accordion.Header>

                                    <Accordion.Body>
                                        { renderQuestions(tab.panels) }
                                    </Accordion.Body>
                                </Accordion.Item>
                            )
                        })}
                    </Accordion>

                    <Row>
                        <Col
                            sm='12'
                            className='d-flex justify-content-between mt-2 mb-2'
                        >
                            {!!buttonsSettings.back?.display && (
                                <Button
                                    onClick={() => {
                                        if (buttonsSettings.back?.onClick) {
                                            buttonsSettings.back.onClick()
                                        }
                                    }}
                                    disabled={!buttonsSettings.back?.enable}
                                    color='btn btn-secondary'
                                    className={`${buttonsSettings?.back?.customClass}`}
                                >
                                    {buttonsSettings?.back?.title || t('Back')}
                                </Button>
                            )}

                            {!!buttonsSettings.submit?.display && (
                                <Button
                                    disabled={!buttonsSettings.submit?.enable}
                                    type='submit'
                                    color='primary'
                                    className={`${buttonsSettings?.submit?.customClass}`}
                                >
                                    {buttonsSettings?.submit?.title || t('Submit')}
                                </Button>
                            )}
                        </Col>
                    </Row>
                </form>
            </div>
        </FormProvider>
    )
})