import * as React from 'react'
import { withNamespaces } from 'react-i18next'
import { FormGroup, Label, Input, FormFeedback } from 'reactstrap'
import { Controller } from 'react-hook-form'

const Date = withNamespaces()(({ 
    name, 
    label, 
    validation, 
    displayQuestion, 
    t,
    questionTitlePrefix, 
    ...rest }) => {
    const {
        control,
        formState: { errors, defaultValues }
    } = rest

    React.useEffect(() => {
        control.register(name, {
            required: !!validation?.required
        })
        
        return () => {
            control.unregister(name)
        }
    }, [])

    React.useEffect(() => {
        if(!displayQuestion){
            control.unregister(name)
        }
        else{
            control.register(name, {
                required: !!validation?.required
            })
        }
    }, [ displayQuestion ])

    if(!displayQuestion) return null

    return (
        <FormGroup>
            {label && <Label for={name}>
                {questionTitlePrefix}{t(label)}
                </Label>}
            <Controller
                name={name}
                control={control}
                render={({ field }) => {
                    return (
                        <Input
                            {...field}
                            type='date'
                            defaultValue={defaultValues[name]}
                            onBlur={(e) => {
                                field.onBlur(e)
                            }}
                            id={name}
                        />
                    )
                }}
            />
            {errors && errors[name] && (
                <FormFeedback className='d-block' valid={false}>
                    {t(`This field cannot be blank`)}
                </FormFeedback>
            )}
        </FormGroup>
    )
})

export { Date }
