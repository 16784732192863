import * as React from 'react'
import { withNamespaces } from 'react-i18next'
import { FormGroup, Label, Input, FormFeedback } from 'reactstrap'
import { Controller } from 'react-hook-form'

const TextBox = withNamespaces()(({
    name,
    label,
    validation,
    displayQuestion,
    questionTitlePrefix,
    t,
    ...rest
}) => {
    const {
        control,
        formState: { errors }
    } = rest
    
    React.useEffect(() => {
        control.register(name, {
            required: !!validation?.required
        })
        
        return () => {
            control.unregister(name)
        }
    }, [])

    React.useEffect(() => {
        if(!displayQuestion){
            control.unregister(name)
        }
        else{
            control.register(name, {
                required: !!validation?.required
            })
        }
    }, [ displayQuestion ])

    return (
        <FormGroup>
            {label && <Label for={name}>
                {questionTitlePrefix}{t(label)}
                </Label>}
            <Controller
                name={name}
                control={control}
                render={({ field: {
                    onChange,
                    onBlur,
                    value,
                    ...fieldElseProps
                } }) => (
                    <Input
                        {...fieldElseProps}
                        defaultValue={value}
                        onBlur={(e) => {
                            onBlur(e)
                            onChange(e)
                        }}
                        type='text'
                        id={name}
                    />
                )}
            />
            {errors && errors[name] && (
                <FormFeedback className='d-block' valid={false}>
                    {t(`This field cannot be blank`)}
                </FormFeedback>
            )}
        </FormGroup>
    )
})

export { TextBox }
