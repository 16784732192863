import {
    QuestionnairePagesImages
} from '../../constants'

const QuestionnaireEmptyImageSrc = '/images/questionnaire/pages/no-pictures.png'
const UnknownFileTypePlaceholder = '/images/questionnaire/no-pictures.png'

export {
    QuestionnairePagesImages,
    QuestionnaireEmptyImageSrc,
    UnknownFileTypePlaceholder
}