import * as React from 'react'
import { withNamespaces } from 'react-i18next'
import { FormGroup, Label, Input, FormFeedback, Row, Col } from 'reactstrap'
import { Controller } from 'react-hook-form'

const RadioBox = withNamespaces()(({
    name,
    label,
    validation,
    options,
    subQuestionRenerer,
    displayQuestion,
    questionTitlePrefix,
    t,
    ...rest
}) => {
    const {
        control,
        formState: { errors, defaultValues }
    } = rest
    const [currentSelectedOption, setCurrentSelectedOption] =
        React.useState(null)

    const renderOptionSubItem = (control, errors) => {
        if (!['date', 'text'].includes(currentSelectedOption.type)) return null

        const subInputName = `${name}-${currentSelectedOption.type}`
        control.register(subInputName, {
            // required: !!validation?.required
            required: true
        })
        return (
            <div>
                <Controller
                    name={subInputName}
                    control={control}
                    render={({ field }) => {
                        if (currentSelectedOption.type === 'date') {
                            return (
                                <Input
                                    {...field}
                                    type='date'
                                    defaultValue={defaultValues[subInputName]}
                                />
                            )
                        }
                        return <Input {...field} type='text' />
                    }}
                />
                {errors && errors[subInputName] && (
                    <FormFeedback className='d-block' valid={false}>
                        {t(`This field cannot be blank`)}
                    </FormFeedback>
                )}
            </div>
        )
    }

    const renderChildren = () => {
        const children = currentSelectedOption?.children || []

        const diplayChildren = children ? true : false
        
        return (
            <div className={`children-questions-container ${!diplayChildren ? 'd-none' : ''}`}>
                {children.map(
                    (subQuestion, subQuestionIndex) => {
                        return (
                            <div
                                className='children-question'
                                key={subQuestionIndex}
                            >
                                {subQuestionRenerer({
                                    ...subQuestion,
                                    parentQuestion: name,
                                    options: (subQuestion.type === 'radiobox' ? (subQuestion.options ? subQuestion.options : [
                                        {
                                            id: 1,
                                            title: 'Yes'
                                        },
                                        {
                                            id: 0,
                                            title: 'No'
                                        }
                                    ]) : subQuestion.options || [])
                                })}
                            </div>
                        )
                    }
                )}
            </div>
        )
    }

    React.useEffect(() => {
        if (defaultValues[name]) {
            const optionToSelectAsDefault = options.find((option) => {
                return (
                    (option?.id || '').toString() ===
                    (defaultValues[name] || '').toString()
                )
            })

            if (optionToSelectAsDefault) {
                setCurrentSelectedOption(optionToSelectAsDefault)
            }
        }

        control.register(name, {
            required: !!validation?.required
        })
        
        return () => {
            control.unregister(name)

            if(currentSelectedOption){
                control.unregister(`${name}-${currentSelectedOption.type}`)
                setCurrentSelectedOption(null)
            }
        }
    }, [])

    React.useEffect(() => {
        if(!displayQuestion){
            control.unregister(name)

            if(currentSelectedOption){
                control.unregister(`${name}-${currentSelectedOption.type}`)
                setCurrentSelectedOption(null)
            }
        }
        else{
            control.register(name, {
                required: !!validation?.required
            })
        }
    }, [ displayQuestion ])

    if(!displayQuestion) return null

    return (
        <FormGroup>
            {label && <Label for={name}>
                {questionTitlePrefix}{t(label)}
                </Label>}
            <Controller
                name={name}
                control={control}
                render={({ field }) => {
                    return (
                        <div className='sub-question-row'>
                            {options.map((option, index) => {
                                return (
                                    <div
                                        key={index}
                                        className='sub-question-col'
                                    >
                                        <Label>
                                            <Input
                                                {...field}
                                                defaultChecked={
                                                    (
                                                        defaultValues[
                                                            name
                                                        ] || ''
                                                    ).toString() ===
                                                    (
                                                        option?.id || ''
                                                    ).toString()
                                                }
                                                type='radio'
                                                value={option?.id}
                                                onChange={(e) => {
                                                    field.onChange(e)
                                                    setCurrentSelectedOption(option)
                                                }}
                                            />{' '}
                                            {t(option?.title)}
                                        </Label>
                                        {currentSelectedOption &&
                                            currentSelectedOption.id ===
                                                option.id && (
                                                <div className='custom-radio-option-type'>
                                                    {renderOptionSubItem(
                                                        control,
                                                        errors
                                                    )}
                                                </div>
                                            )}
                                    </div>
                                )
                            })}
                        </div>
                    )
                }}
            />
            {errors && errors[name] && (
                <FormFeedback className='d-block' valid={false}>
                    {t(`This field cannot be blank`)}
                </FormFeedback>
            )}
            {renderChildren()}
        </FormGroup>
    )
})

export { RadioBox }
