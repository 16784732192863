import {
    QuestionnairePagesImages,
    QuestionnaireEmptyImageSrc
} from '../../constants'

const QuestionTypes = [
    {
        title: 'Single Line Text',
        value: 'text',
        icon: 'ri-menu-line',
        placeholder: 'Single Line Text',
        isDefault: true
    },
    {
        title: 'Date Input',
        value: 'date',
        icon: 'ri-calendar-2-line',
        placeholder: 'Date Input',
        isDefault: false
    },
    {
        title: 'Legal Entity',
        value: 'legal_entity',
        icon: 'ri-git-merge-line',
        placeholder: 'Legal Entity Select',
        isDefault: false
    },
    {
        title: 'Switch',
        value: 'switch',
        icon: 'ri-toggle-line',
        placeholder: 'True/False',
        isDefault: false
    },
    {
        title: 'Long Text',
        value: 'textarea',
        icon: 'ri-menu-line',
        placeholder: 'Multi Line Text Input',
        isDefault: false
    },
    {
        title: 'Radio Button Group',
        value: 'radiobox',
        icon: 'ri-radio-button-line',
        placeholder: 'Radiobox',
        isDefault: false
    },
    {
        title: 'Checkboxes',
        value: 'checkbox',
        icon: 'ri-checkbox-line',
        placeholder: 'Checkbox',
        isDefault: false
    },
    {
        title: 'Select Box',
        value: 'select',
        icon: 'ri-list-check',
        placeholder: 'Select ...',
        isDefault: false
    },
    {
        title: 'File Uploader',
        value: 'file_upload',
        icon: 'ri-file-upload-line',
        placeholder: 'File Uploader',
        isDefault: false
    }
]

const SubQuestionTypes = [
    {
        title: 'Single Line Text',
        value: 'text',
        icon: 'ri-menu-line',
        placeholder: 'Single Line Text',
        isDefault: true
    },
    {
        title: 'Date Input',
        value: 'date',
        icon: 'ri-calendar-2-line',
        placeholder: 'Date Input',
        isDefault: false
    },
    {
        title: 'Long Text',
        value: 'textarea',
        icon: 'ri-menu-line',
        placeholder: 'Multi Line Text',
        isDefault: false
    },
    {
        title: 'Radio Button Group',
        value: 'radiobox',
        icon: 'ri-radio-button-line',
        placeholder: 'Radiobox',
        isDefault: false
    },
    {
        title: 'Checkboxes',
        value: 'checkbox',
        icon: 'ri-checkbox-line',
        placeholder: 'Checkbox',
        isDefault: false
    },
    {
        title: 'Select Box',
        value: 'select',
        icon: 'ri-list-check',
        placeholder: 'Select ...',
        isDefault: false
    },
    {
        title: 'File Uploader',
        value: 'file_upload',
        icon: 'ri-file-upload-line',
        placeholder: 'File Uploader',
        isDefault: false
    }
]

export { QuestionTypes, SubQuestionTypes, QuestionnairePagesImages, QuestionnaireEmptyImageSrc }
