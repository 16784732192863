import * as React from 'react'
import { withNamespaces } from 'react-i18next'
import ReactSelect from 'react-select'
import { FormGroup, Label, FormFeedback } from 'reactstrap'
import { Controller } from 'react-hook-form'

const Select = withNamespaces()(({
    name,
    label,
    validation,
    options,
    displayQuestion,
    subQuestionRenerer,
    questionTitlePrefix,
    t,
    ...rest
}) => {
    const {
        control,
        formState: { errors, defaultValues }
    } = rest

    const [currentSelectedValue, setCurrentSelectedValue] =
        React.useState(null)

    const selectOptions = options.map((option) => {
        return {
            value: option.id,
            label: t(option.title)
        }
    })

    const renderChildren = () => {
        if (
            currentSelectedValue &&
            currentSelectedValue?.children &&
            currentSelectedValue.children.length > 0
        ) {
            return (
                <div className='children-questions-container'>
                    {currentSelectedValue.children.map(
                        (subQuestion, subQuestionIndex) => {
                            return (
                                <div
                                    className='children-question'
                                    key={subQuestionIndex}
                                >
                                    {subQuestionRenerer({
                                        ...subQuestion,
                                        parentQuestion: name,
                                        options: (subQuestion.type === 'radiobox' ? (subQuestion.options ? subQuestion.options : [
                                            {
                                                id: 1,
                                                title: 'Yes'
                                            },
                                            {
                                                id: 0,
                                                title: 'No'
                                            }
                                        ]) : subQuestion.options || [])
                                    })}
                                </div>
                            )
                        }
                    )}
                </div>
            )
        }
    }

    React.useEffect(() => {
        if (Array.isArray(defaultValues[name])) {
            const optionToSelectAsDefault = options.find((option) => {
                return (parseInt(option.id) === parseInt(defaultValues[name][0]?.value))
            })

            if (optionToSelectAsDefault) {
                setCurrentSelectedValue(optionToSelectAsDefault)
            }
        }

        control.register(name, {
            required: !!validation?.required
        })
        
        return () => {
            control.unregister(name)
        }
    }, [])

    React.useEffect(() => {
        if(!displayQuestion){
            control.unregister(name)
        }
        else{
            control.register(name, {
                required: !!validation?.required
            })
        }
    }, [ displayQuestion ])

    return (
        <FormGroup>
            {label && <Label for={name}>
                {questionTitlePrefix}{t(label)}
                </Label>}
            <Controller
                name={name}
                control={control}
                render={({ field }) => (
                    <ReactSelect
                        {...field}
                        isClearable={false}
                        options={selectOptions}
                        menuPortalTarget={document.body}
                        onChange={(e) => {
                            field.onChange(e)

                            setCurrentSelectedValue(options.find((option) => {
                                return option.id === e.value
                            }))
                        }}
                    />
                )}
            />
            {errors && errors[name] && (
                <FormFeedback className='d-block' valid={false}>
                    {t(`This field cannot be blank`)}
                </FormFeedback>
            )}
            {renderChildren()}
        </FormGroup>
    )
})

export { Select }
